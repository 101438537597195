import { FC, useCallback, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { Input, Table, Tooltip } from '@nordictrustee/nt-ui-library';
import { Hidden } from 'components/form';
import { InformationCovenantDTO } from '../../../reportingApi.types';
import { centerColumnText, firstColPadding } from '../../tableStyles';

export const Deliveries: FC<{ informationCovenants: InformationCovenantDTO[], onChange: (informationCovenants: InformationCovenantDTO[]) => void }> = ({ informationCovenants, onChange }) => {
  const onReportUpdate = useCallback((item: InformationCovenantDTO, checked: boolean) => {
    const update = informationCovenants.map((covenant) => {
      if (covenant.id === item.id) {
        return { ...covenant, isAvailableFromPublicSources: checked };
      }
      return covenant;
    });
    onChange(update);
  }, [informationCovenants, onChange]);

  const isAvailableFromPublicSourcesTitle = "This box shall be ticked if documentation is available from public sources, like a homepage or newsweb.no and therefore not uploaded under the reporting section below.";

  const columns: Table.Column<InformationCovenantDTO>[] = useMemo(() => [
    {
      field: 'isAvailableFromPublicSources',
      title: (
        <Table.ColumnWithTooltip title={isAvailableFromPublicSourcesTitle}>
          Available from public sources
        </Table.ColumnWithTooltip>
      ),
      width: '25%',
      render: ((rowData) =>
        <Tooltip title={isAvailableFromPublicSourcesTitle}>
          <span>
            <Input.Checkbox
              label={<Hidden>Available from public sources</Hidden>}
              onChange={(checked) => onReportUpdate(rowData, checked)}
              checked={rowData.isAvailableFromPublicSources}
            />
          </span>
        </Tooltip>
      ),
      ...centerColumnText
    },
    {
      field: 'name',
      title: 'Documentation',
      ...firstColPadding
    },
    {
      field: '',
      title: '',
      width: '20%'
    }
  ], [onReportUpdate]);

  return (
    <Box>
      <Typography variant="h3" mb={1}>Deliveries acording to the bond terms</Typography>
      <Table.Root
        columns={columns}
        data={informationCovenants}
        options={{
          paging: false,
          search: false,
          toolbar: false,
          maxBodyHeight: '70vh',
          actionsColumnIndex: -1,
          searchFieldAlignment: 'left',
          pageSizeOptions: [10, 25, 50, 100],
          rowStyle: { padding: '0 1rem' },
        }}
      />
    </Box>
  );
};